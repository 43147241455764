























import { Vue, Component, Prop } from 'vue-property-decorator';

import Dialog from '@/shared/resources/components/dialogs/Dialog.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import LoaderLinear from '@/shared/resources/components/LoaderLinear.vue';

@Component({
  components: {
    LoaderLinear,
    Card,
    Dialog,
  },
})
export default class ProgressDialog extends Vue {
  /**
   * Props
   */
  @Prop() private value!: boolean;
  @Prop({ default: 'Wczytywanie...' }) private text!: string | null;
  @Prop({ default: 300 }) private width!: number;

  /**
   * Getters
   */
  private get displayText(): boolean {
    return !!this.text;
  }
}









































































import { Component, Vue } from 'vue-property-decorator';

import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import Button from '@/shared/resources/components/buttons/Button.vue';
import CartSummaryItemsTable from '@/app/modules/cart/components/partials/summary/CartSummaryItemsTable.vue';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';
import CartCoreModel from '@/modules/carts/models/CartCoreModel';
import Cart from '@/app/lib/cart/Cart';
import Order from '@/app/lib/order/Order';
import CartSummaryDetails from '@/app/modules/cart/components/partials/summary/CartSummaryDetails.vue';
import CartSummaryTotals from '@/app/modules/cart/components/partials/summary/CartSummaryTotals.vue';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';
import CartSummaryDeliveryData from '@/app/modules/cart/components/partials/summary/CartSummaryDeliveryData.vue';
import CartSummaryContactData from '@/app/modules/cart/components/partials/summary/CartSummaryContactData.vue';
import ButtonsRow from '@/shared/resources/components/buttons/ButtonsRow.vue';
import Popup from '@/shared/lib/support/popups/Popup';
import ProgressDialog from '@/shared/resources/components/dialogs/ProgressDialog.vue';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import Sticky from '@/shared/resources/components/Sticky.vue';
import IndexableInterface from '@/core/interfaces/IndexableInterface';

@Component({
  components: {
    Sticky,
    GridSpacer,
    ProgressDialog,
    ButtonsRow,
    CartSummaryContactData,
    CartSummaryDeliveryData,
    CartSummaryTotals,
    CartSummaryDetails,
    CartSummaryItemsTable,
    Button,
    Card,
    GridCol,
    GridRow,
  },
})
export default class CartOrderSummary extends Vue {
  /**
   * Data
   */
  private progressDialog: boolean = false;

  /**
   * Getters
   */
  private get dataToCreate(): any {
    return Order.dataToCreateRequest(Cart.items());
  }

  private get order(): OnlineOrderCoreModel | null {
    return Order.model;
  }

  private get totalsFormatted(): AmountsFormattedInterface {
    return Order.totalsFormatted;
  }

  private get deliveryPriceFormatted(): AmountsFormattedInterface {
    return Order.deliveryPriceFormatted;
  }

  private get items(): CartItemCoreModel[] {
    return Cart.items();
  }

  /**
   * Display getters
   */
  private get displaySummary(): boolean {
    return !!this.order;
  }

  private get displayDeliveryData(): boolean {
    return Order.deliveryConfig?.requireAddress || false;
  }

  /**
   * Methods
   */
  private async confirmOrder() {
    this.showProgressDialog();

    try {
      const response = await OnlineOrderCoreModel
        .apiExt()
        .data(this.dataToCreate)
        .create();

      Order.processing = true;
      redirect({ name: 'cart.success', params: { id: response.id } });
    } catch (ex) {
      if (ex.hasError('not_enough_products_amount')) {
        if (ex.data) {
          const correctProductsAmounts: IndexableInterface = ex.data;
          Object.keys(correctProductsAmounts).forEach((productId) => {
            Order.updateProductAmount(parseInt(productId, 0), correctProductsAmounts[productId]);
          });
        }

        await Popup.error(
          'Przejdź do koszyka i popraw brakujące produkty.',
          null,
          'Brak wystarczających stanów',
        );

        redirect({name: 'cart'});
      } else {
        Popup.error('Wystąpił błąd podczas składania zamówienia. Prosimy o&nbsp;kontakt.');
      }
    }

    this.hideProgressDialog();
  }

  private showProgressDialog() {
    this.progressDialog = true;
  }

  private hideProgressDialog() {
    this.progressDialog = false;
  }

  /**
   * Handlers
   */
  private async onConfirmButtonClick() {
    try {
      await Popup.confirm('Na pewno chcesz złożyć zamówienie?');
      this.confirmOrder();
    } catch (ex) {
      // do nothing
    }
  }
}

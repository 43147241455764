














import { Component, Vue } from 'vue-property-decorator';

import MainHeader from '@/shared/resources/components/MainHeader.vue';
import CartSummary from '@/app/modules/cart/components/CartOrderSummary.vue';
import Cart from '@/app/lib/cart/Cart';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import Order from '@/app/lib/order/Order';

@Component({
  components: {
    CartSummary,
    MainHeader,
  },
})
export default class CartSummaryView extends Vue {
  /**
   * Lifecycle hooks
   */
  private created() {
    this.checkOrderIfNotEmpty();
  }

  /**
   * Methods
   */
  private checkOrderIfNotEmpty() {
    if (Cart.isEmpty() || !Order.isValid()) {
      redirect('/cart');
    }
  }
}
